.openButton {
  cursor: pointer;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: white;
  box-shadow: 24;
  padding: 32px;
  border-radius: 40px 5px;
}

.button {
  display: flex;
  justify-content: center;
}

.wikipediaLink {
  text-align: center;
}

.duck{
  margin:10px auto;
  height: 80px;
  display: block;
}

.headerText {
  font-style: italic;
  text-align: center;
}
