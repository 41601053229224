.main {
  display: flex;
  justify-content: center;
}

.rectangle {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.textarea {
  width: 400px;
  background-color: white;
  border-radius: 4px;
  box-shadow: white 0 0 20px 10px;
}

.answer {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 40px;
  border-radius: 40px 5px;
  text-shadow: white 0 0 4px;
  color: black;
  font-weight: bold;
  margin: 0 20px 20px;
}

@media only screen and (max-width: 440px) {
  .textarea {
    max-width: calc(100% - 40px);
    width: calc(100% - 40px);
  }
}
