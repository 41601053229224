.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main{
  position: relative;
  z-index: 1;
}

.app-background{
  position: fixed;
  height:100%;
  width:100vw;
  overflow:hidden;
}

#top {
  width: 100%;
  height: 67%;
  background: linear-gradient(transparent 50%, hotpink 160%);
  box-shadow: 0 15px 50px 1px white;
  position: absolute;
  top: 0;
  left:0;
}

#sky {
  position: absolute;
  top: 0;
  width: 100%;
  animation: move 30s linear infinite;
  background: repeating-linear-gradient(pink 2px,transparent 3px,transparent 45px),repeating-linear-gradient(90deg,pink 2px,transparent 3px,transparent 50px) 10px 0;
  filter: drop-shadow(0 0 1px magenta) drop-shadow(0 0 3px magenta) drop-shadow(0 0 7px cyan);
  height: 99%;
  opacity: .2;
}

#bottom {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  transform: perspective(500px) rotateX(65deg);
  bottom: -15%;
  background: linear-gradient(
    90deg,
    hotpink -60%,
    transparent 40%,
    transparent 60%,
    hotpink 140%
  );
  clip-path: polygon(0 100%, 0 55%, 100% 55%, 100% 100%);
}

#ground {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 54%;
  background: repeating-linear-gradient(
    white 2px,
    transparent 3px,
    transparent 40px
  ),
  repeating-linear-gradient(90deg, white 2px, transparent 3px, transparent 50px);
  filter: drop-shadow(0 0 1px cyan) drop-shadow(0 0 3px cyan) drop-shadow(0 0 7px cyan);
  animation: move 30s linear infinite;

}

#duck{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX( -50%);
  opacity:0.7;
  animation: hueshift 10s ease-in-out infinite;
}

.duck-body{
  border-bottom: 100px solid #96875c;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 200px;
  position: relative;
  z-index: 1;
}

#duck:before{
  content:' ';
  position: absolute;
  height: 100px;
  left: -20px;
  top: -62px;
  width: 100px;
  border-radius:50%;
  background-color: rgb(250, 250, 52);
}

#duck:after{
  border-color: transparent #d2550e transparent transparent;
  border-width: 10px 30px 10px 0;
  content: " ";
  position: absolute;
  border-style: solid;
  top: -24px;
  left: -49px;

}

@keyframes move {
  to {
    background-position-y: 2000px;
  }
}

@keyframes hueshift {
  0% {
    filter: hue-rotate(0deg) drop-shadow(0 0 40px white);
  }
  100% {
    filter: hue-rotate(360deg) drop-shadow(0 0 40px white);
  }
}
