.title {
  font-style: italic;
  background-size: 100%;
  background-color: cyan;
  background-image: linear-gradient(45deg, cyan, magenta);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  line-height: 2 !important;
}
