.account {
  display: flex;
  justify-content: center;
  padding: 0 20px 20px;
}

.grid {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 40px 5px;
  max-width: 600px;
  margin-left: 0 !important;
}

.gridItem {
  width: 100%;
  padding-left: 0 !important;
}

.input{
  background-color:whitesmoke;
  width: 560px;
  border-radius: 4px;
  box-shadow: white 0 0 20px 10px;
}

@media only screen and (max-width: 640px) {
  .grid {
    max-width: calc(100% - 20px);
  }
  .input {
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);
  }
}
